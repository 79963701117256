<template lang="html">
  <div class="listaConsultas">
    <br>
    <vs-button color="primary"   class="float-right" style="margin-right:5%;margin-bottom:2%;"@click="continuar">Continuar</vs-button>
    <vs-button color="primary"  icon-pack="feather" icon="icon-printer" class="float-right" style="margin-right:5%;margin-bottom:2%;"@click="print">Imprimir</vs-button>
    <br>
    <div class="personales">
          <p><b>Personas: {{solicitante.Nombres}} {{solicitante.ApellidoPaterno}} {{solicitante.ApellidoMaterno}}</b></p>
          <p>Domicilio: {{solicitante.DireccionIne}}</p>
          <p>Fecha de nacimiento: {{solicitante.FechaNacimiento}}</p>
          <p>RFC: {{solicitante.Rfc}}</p>
          <p class="fico"><b>Calificación FICO {{score}}</b></p>
          <ul>
            <p><li>De 300 a 550. Malo</li></p>
            <p><li>De 551 a 650. Regular</li></p>
            <p><li>De 651 a 750. Bueno</li></p>
            <p><li>De 751 a 850. Excelente</li></p>
          </ul>
        </div>
    <vs-table :data="creditos" max-items="100"
      pagination
      class="tabla">
      <template slot="header">
      </template>
      <template slot="thead">
        <vs-th style="font-size:10px;">
          Información
        </vs-th>
        <vs-th style="font-size:10px;">
          Fecha apertura
        </vs-th>
        <vs-th style="font-size:10px;">
          Fecha actualización
        </vs-th>
        <vs-th style="font-size:10px;">
          Monto a pagar
        </vs-th>
        <vs-th style="font-size:10px;">
          Limite credito
        </vs-th>
        <vs-th style="font-size:10px;">
          Concepto pago
        </vs-th>
        <vs-th style="font-size:10px;">
          Saldo actual
        </vs-th>
        <vs-th style="font-size:10px;">
          Comportamiento
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].Otorgante" style="font-size:10px;">
            {{data[indextr].Otorgante}}, {{data[indextr].Cuenta}}, {{data[indextr].Tipo}}
          </vs-td>

          <vs-td :data="data[indextr].Apertura" style="font-size:10px;">
            {{data[indextr].Apertura}}
          </vs-td>

          <vs-td :data="data[indextr].FechaActualizacion" style="font-size:10px;">
            {{data[indextr].FechaActualizacion}}
          </vs-td>

          <vs-td :data="data[indextr].MontoAPagar" style="font-size:10px;">
            ${{data[indextr].MontoAPagar}}
          </vs-td>
          <vs-td :data="data[indextr].LimiteDeCredito" style="font-size:10px;">
            ${{data[indextr].LimiteDeCredito}}
          </vs-td>
          <vs-td :data="data[indextr].ConceptoDePago" style="font-size:10px;">
            {{data[indextr].ConceptoDePago}}
          </vs-td>
          <vs-td :data="data[indextr].SaldoActual" style="font-size:10px;">
            ${{data[indextr].SaldoActual}}
          </vs-td>
          <vs-td :data="data[indextr].Comportamiento" v-html="data[indextr].Comportamiento" class="comportamiento">
            {{data[indextr].Comportamiento}}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>

</template>

<script>
export default {
  data(){
    return{
      email:'',
      creditos:[],
      consultaCirculo:{},
      solicitante:{},
      caso:''
    }
  },
  mounted(){
    this.email=this.$route.params.id
    this.caso=this.$route.params.caso
    this.getApikey()
    this.consultar()
    this.dameSolicitante()
  },
  methods:{
    consultar(){
      let self=this
      var objRequestConsultaBuro = {
        strApiKey: this.key,
        strMetodo: 'ConsultaBuro',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestConsultaBuro,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.consultaCirculo=response.data.objContenido.objGeneral
            this.creditos=response.data.objContenido.vctCreditos
            for (var i = 0; i < this.creditos.length; i++) {
              this.creditos[i].LimiteDeCredito = this.creditos[i].LimiteDeCredito.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.creditos[i].SaldoActual = this.creditos[i].SaldoActual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.creditos[i].MontoAPagar = this.creditos[i].MontoAPagar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            this.score=response.data.objContenido.objGeneral.strScore
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ConsultaBuro',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self=this
      var objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.solicitante=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    print(){
      var is_chrome = Boolean(window.chrome);
      if (is_chrome) {
        setTimeout(function () {
            window.print();
            window.close();
        }, 200);
      }
      else {
          window.print();
          window.close();
      }
    },
    continuar(){
        this.$router.push('/resultados/'+this.caso).catch(() => {})
    }
  }
}
</script>

<style lang="css" scoped>
.personales{
  margin-left: 3%;
  margin-bottom: 3%;
}
@media print {  
    button, img{
      display: none;
    }
    .bankName{
      display: block;
    }
    .tabla{
       width: 100%;
       margin-right: 0px;
       margin-left: 0px;
     }
     .vs-con-table{
       margin-right: 0px;
       margin-left: 0px;
     }}
</style>
